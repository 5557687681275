import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetEmployeeQuery, useGetEmployeesQuery } from "../../app/services/employees_api";
import { selectIsSignedIn } from "../auth/selectors";
import { setCurrentEmployee, setEmployees } from "./actions";

/**
 * @description Fetches the current employee and syncs it with the store.
 * @returns {Object} An object containing the current employee, and booleans indicating whether the current employee is pending or errored.
 */
export const useFetchAndSyncCurrentEmployee = () => {
  const dispatch = useAppDispatch();
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const {
    data: currentEmployee,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    isUninitialized,
  } = useGetEmployeeQuery(undefined, { skip: !isSignedIn });

  useEffect(() => {
    currentEmployee && dispatch(setCurrentEmployee(currentEmployee));
  }, [dispatch, currentEmployee]);

  const currentEmployeeIsPending = isUninitialized || isLoading || isFetching;
  const currentEmployeeIsError = isError || (isSuccess && !currentEmployee);

  return useMemo(
    () => ({ currentEmployee, currentEmployeeIsError, currentEmployeeIsPending }),
    [currentEmployee, currentEmployeeIsError, currentEmployeeIsPending]
  );
};

/**
 * @description Fetches all employees and syncs them with the store.
 * @returns {Object} An object containing all employees, and booleans indicating whether the employees are pending or errored.
 */
export const useFetchAndSyncAllEmployees = () => {
  const dispatch = useAppDispatch();
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const {
    data: employees,
    isError,
    isFetching,
    isLoading,
    isSuccess,
  } = useGetEmployeesQuery(undefined, {
    skip: !isSignedIn,
  });

  useEffect(() => {
    employees && dispatch(setEmployees(employees));
  }, [dispatch, employees]);

  const employeesIsPending = isLoading || isFetching;
  const employeesIsError = isError || (isSuccess && !employees);

  return useMemo(
    () => ({ employees, employeesIsError, employeesIsPending }),
    [employees, employeesIsError, employeesIsPending]
  );
};
