import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { JSONValue } from "../../app/types";
import { LocalStorageKey, StorageContextTools } from "./types";
import {
  getItem,
  hasLocalStorage,
  setItem,
  StorageListener,
  subscribeToStorageChange,
  unsubscribeStorageChange,
} from "./utils";

const initialContext: StorageContextTools = {
  getStoredState: () => null,
  setStoredState: () => undefined,
};

const StorageContext = createContext<StorageContextTools>(initialContext);

export const useStorageContext = () => {
  const context = useContext(StorageContext);
  if (!context) throw new Error("Storage context not available");
  return context;
};

export interface StorageContextProviderProps {
  defaultValues?: Partial<Record<LocalStorageKey, JSONValue>>;
  children: any;
}

export const StorageContextProvider = ({ children, defaultValues }: StorageContextProviderProps) => {
  const [value, triggerRender] = useState({ key: "" });
  const defaultsRef = useRef(defaultValues);
  const defaultsStoredRef = useRef(false);

  const getStoredState = useCallback(<T,>(key: LocalStorageKey) => getItem<T>(key), []);
  const setStoredState = useCallback(<T,>(key: LocalStorageKey, val: T) => setItem<T>(key, val), []);

  useEffect(() => {
    const listener: StorageListener = key => triggerRender({ key });
    subscribeToStorageChange(listener);
    return () => unsubscribeStorageChange(listener);
  }, []);

  const storeDefaults = useCallback(() => {
    const { current: defaults } = defaultsRef;
    if (!hasLocalStorage || !defaults) return;
    try {
      Object.entries(defaults).forEach(([storageKey, defaultVal]) => {
        const existingVal = getStoredState(storageKey as LocalStorageKey);
        if (existingVal === null && defaultVal !== null) {
          window.localStorage.setItem(storageKey, JSON.stringify(defaultVal));
        }
      });
    } catch (_) {
      return;
    }
  }, [defaultsRef, getStoredState]);

  if (!defaultsStoredRef.current) {
    storeDefaults();
    defaultsStoredRef.current = true;
  }

  const tools = useMemo(
    () => ({
      // This value exists on the object in order to make sure a new tools object is
      // created when a new storage value is set, thereby getting components using
      // this context to actually re-render.
      lastUpdatedKey: value,
      getStoredState,
      setStoredState,
    }),
    [getStoredState, setStoredState, value]
  );

  return <StorageContext.Provider value={tools}>{children}</StorageContext.Provider>;
};
