import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router";
import { runtimeConfig } from "./constants/runtime_config";
import { revision } from "./revision";

export const initializeSentry = () => {
  Sentry.init({
    dsn: runtimeConfig.SENTRY_DSN,
    environment: runtimeConfig.EVERNOW_ENV ?? "unknown",
    release: revision,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.01, // 1% (EHR traffic is less substantial than www)

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replays.  1.0 is 100%
    replaysSessionSampleRate: 0,

    // Capture Replays once an error is encountered.  1.0 is 100%
    replaysOnErrorSampleRate: 0,
  });
};
