import { useCallback } from "react";
import { LocalStorageKey } from "../types";
import useStoredState from "./use_stored_state";

const useStoredBoolean = (key: LocalStorageKey): [boolean, VoidFunction, VoidFunction] => {
  const [value, setValue] = useStoredState<boolean>(key);
  const setTrue = useCallback(() => setValue(true), [setValue]);
  const setFalse = useCallback(() => setValue(false), [setValue]);
  return [value, setTrue, setFalse];
};

export default useStoredBoolean;
