import { AppThunk } from "../../app/store";

import { featureFlagsSlice } from "./slice";
import { Flags } from "./types";

const { actions } = featureFlagsSlice;

export const setFlags =
  (flags: Flags): AppThunk =>
  dispatch => {
    dispatch(actions.setFlags(flags));
  };
