import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const selectEmployeesStore = ({ employees }: RootState) => employees;

export const selectAllEmployees = createSelector(selectEmployeesStore, ({ employees }) => employees);
export const selectActiveEmployees = createSelector(selectAllEmployees, employees => employees.filter(e => e.active));
export const selectInactiveEmployees = createSelector(selectAllEmployees, employees =>
  employees.filter(e => !e.active)
);

export const selectCurrentEmployee = createSelector(selectEmployeesStore, ({ currentEmployee }) => currentEmployee);
export const selectCurrentEmployeeId = createSelector(selectCurrentEmployee, ({ id }) => id);
export const selectCurrentEmployeeAvatar = createSelector(selectCurrentEmployee, ({ avatar }) => avatar);
export const selectCurrentEmployeeAvatarUrl = createSelector(selectCurrentEmployeeAvatar, avatar => avatar?.url);
export const selectCurrentEmployeeDateOfBirth = createSelector(selectCurrentEmployee, ({ dateOfBirth }) => dateOfBirth);
export const selectCurrentEmployeeDisplayName = createSelector(selectCurrentEmployee, ({ displayName }) => displayName);
export const selectCurrentEmployeeFamilyName = createSelector(selectCurrentEmployee, ({ familyName }) => familyName);
export const selectCurrentEmployeeGivenName = createSelector(selectCurrentEmployee, ({ givenName }) => givenName);
export const selectCurrentEmployeeLicenses = createSelector(selectCurrentEmployee, ({ licenses }) => licenses);

export const selectCurrentEmployeeDisplayNameWithCreds = createSelector(
  selectCurrentEmployee,
  ({ displayName, displayNameWithCreds }) => displayNameWithCreds || displayName
);

export const selectCurrentEmployeeDisplayNameWithCredsAndTitle = createSelector(
  selectCurrentEmployee,
  ({ displayName, displayNameWithCredsAndTitle }) => displayNameWithCredsAndTitle || displayName
);

export const selectCurrentEmployeeDisplayNameWithTitle = createSelector(
  selectCurrentEmployee,
  ({ displayName, displayNameWithTitle }) => displayNameWithTitle || displayName
);

export const selectCurrentUser = createSelector(selectCurrentEmployee, ({ user }) => user);
export const selectCurrentUserId = createSelector(selectCurrentUser, ({ id }) => id);
export const selectCurrentUserRoles = createSelector(selectCurrentUser, ({ roles }) => roles);

export const selectCurrentUserPermissions = createSelector(selectCurrentUserRoles, roles => {
  const isAdmin = roles.includes("ADMIN");
  const isEmployee = roles.includes("EMPLOYEE");
  const isNurse = roles.includes("NURSE");
  const isProvider = roles.includes("PROVIDER");
  const isSupport = roles.includes("SUPPORT");
  const isEngineer = roles.includes("ENGINEER");

  return {
    canAddMammogramCheckins: isProvider || isSupport,
    canAssignPatients: isAdmin || isSupport,
    canCancelOrders: isNurse || isProvider || isSupport,
    canCancelPrescriptions: isProvider,
    canCreatePrescriptions: isProvider,
    canEditIntakes: isNurse || isProvider,
    canEditOrderStatus: isAdmin || isProvider || isSupport,
    canEditPatients: isAdmin || isProvider || isSupport,
    canEditPrescriptions: isAdmin || isProvider || isSupport,
    canSelfAssignIgnoringStateRestriction: isAdmin || isSupport,
    hasSupportPowers: isAdmin || isSupport,
    isAdmin,
    isEmployee,
    isNurse,
    isProvider,
    isSupport,
    isEngineer,
  };
});

/**
 * @description Selects all employees except the current employee
 */
export const selectOtherEmployees = createSelector(
  selectActiveEmployees,
  selectCurrentEmployeeId,
  (employees, currentEmployeeId) => employees.filter(({ id }) => id !== currentEmployeeId)
);

export const selectActiveDoctors = createSelector(selectActiveEmployees, employees =>
  employees.filter(({ isDoctor }) => isDoctor)
);
