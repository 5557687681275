import Tooltip from "@evernowinc/harbard/tooltip";
import { CSSProperties } from "react";
import Avatar, { ConfigProvider } from "react-avatar";
import "./avatar.scss";
const palette = ["#D0997E", "#32592E", "#68533F", "#B0C4DE", "#F0E68C", "#DDA0DD", "#9400D3"];

interface AvatarDisplayProps {
  name: string;
  style?: CSSProperties;
  size?: string;
  src?: string;
  tooltipDirection?: "top" | "bottom";
  disableTooltip?: boolean;
}

const AvatarDisplay = ({
  name,
  style,
  size = "70px",
  src,
  tooltipDirection = "bottom",
  disableTooltip = false,
}: AvatarDisplayProps) => {
  return (
    // @ts-ignore - react-avatar 5.0.3 does not declare the children property
    <ConfigProvider colors={palette}>
      <Tooltip title={name} placement={tooltipDirection} disableHoverListener={disableTooltip}>
        <div>
          <Avatar size={size} round name={name} className="avatar has-text-weight-bold" style={style} src={src} />
        </div>
      </Tooltip>
    </ConfigProvider>
  );
};

export default AvatarDisplay;
