import { gql, useQuery } from "@apollo/react-hooks";
import classnames from "classnames";
import * as React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentUserPermissions } from "../../features/employees/selectors";

const RedirectModal = ({ onContinue, onCancel }: { onContinue: () => any; onCancel: () => any }) => {
  const [clickedContinue, setClickedContinue] = React.useState<boolean>(false);

  const handleClickContinue = () => {
    setTimeout(() => setClickedContinue(true), 1000);
    onContinue();
  };

  return (
    <div className={classnames("modal", "is-active")}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">DoseSpot Registration</p>
          <button className="delete" aria-label="close" onClick={onCancel} />
        </header>
        <section className="modal-card-body">
          <p>
            In order to prescribe medications at Evernow, please complete the following HIPAA authorization and identity
            proofing at DoseSpot. Pressing "Continue" will open the these authorization prompts in a new tab. Return
            here after you've completed them and press "Done".
          </p>
        </section>
        <footer className="modal-card-foot">
          <button className={classnames("button is-rounded ")} onClick={handleClickContinue}>
            Continue
          </button>
          <button disabled={!clickedContinue} className={classnames("button is-rounded is-success")} onClick={onCancel}>
            Done
          </button>
        </footer>
      </div>
    </div>
  );
};

type RegistrationState = "init" | "incomplete" | "dismissed" | "complete";

export const DosespotRegistrationModal = () => {
  const { isProvider } = useAppSelector(selectCurrentUserPermissions);

  const [regState, setRegState] = React.useState<RegistrationState>("init");

  const { data, refetch } = useQuery<{ dosespot: { registrationRedirect: string | null } }>(
    gql`
      query {
        dosespot {
          registrationRedirect
        }
      }
    `,
    { skip: !isProvider, fetchPolicy: "network-only" }
  );

  React.useEffect(() => {
    if (!data) return;

    const {
      dosespot: { registrationRedirect },
    } = data;
    setRegState(registrationRedirect ? "incomplete" : "complete");
  }, [data, setRegState]);

  const redirect = () => {
    if (data?.dosespot.registrationRedirect) {
      const popup = window.open(data.dosespot.registrationRedirect);
      const interval = setInterval(() => {
        if (popup && popup.closed) {
          refetch();
          clearInterval(interval);
        }
      }, 250);
    }
  };

  switch (regState) {
    case "incomplete":
      return <RedirectModal onContinue={redirect} onCancel={() => setRegState("dismissed")} />;
    default:
      return null;
  }
};
