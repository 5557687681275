import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetFlagsQuery } from "../../app/services/feature_flags_api";
import { setFlags } from "./actions";
import { selectFlags } from "./selectors";
import { Flags } from "./types";
import { selectCurrentEmployeeId } from "../employees/selectors";
import { useGetFlagOverrides } from "./hooks/use_get_flag_overrides";

export const useInitializeFeatureFlags = () => {
  const dispatch = useAppDispatch();
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const { data: flags } = useGetFlagsQuery(currentEmployeeId);
  const featureFlagOverrides = useGetFlagOverrides();

  useEffect(() => {
    if (flags) {
      dispatch(setFlags({ ...flags, ...featureFlagOverrides }));
    }
  }, [flags, featureFlagOverrides, dispatch]);
};

export const useFlag = (flag: keyof Flags) => {
  const flags = useAppSelector(selectFlags);
  return flags[flag];
};
