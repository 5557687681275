import { AnalyticsBrowser } from "@segment/analytics-next";
import { useCallback, useEffect } from "react";
import { runtimeConfig } from "../../lib/constants/runtime_config";
import type { AnalyticsEvents } from "./types";

const analytics = AnalyticsBrowser.load({
  writeKey: runtimeConfig.SEGMENT_WRITE_KEY ?? "",
});

export const useInitializeAnalytics = (userId?: string, employeeId?: string) => {
  const identify = useCallback(async () => {
    const idTraits = { eid: employeeId };
    await analytics.identify(userId, idTraits, { context: { ip: "0.0.0.0" } });
    if (!runtimeConfig.IS_PRODUCTION) console.log("📈", `Analytics identify: "${userId}"`, idTraits);
  }, [userId, employeeId]);

  useEffect(() => {
    if (userId && employeeId) {
      identify();
    }
  }, [employeeId, identify, userId]);
};

export const track = async (event: AnalyticsEvents) => {
  if (typeof window === "undefined") return;
  const { eventName, ...rest } = event;
  await analytics.track(eventName, rest, { context: { ip: "0.0.0.0" } });
  if (!runtimeConfig.IS_PRODUCTION) console.log("📈", `Analytics event: "${eventName}"`, rest);
};

export const anonymize = () => {
  return analytics.reset();
};
