import { RefObject, useCallback, useEffect } from "react";

const useHandleClickOutside = (clickOutsideOfMeRef: RefObject<HTMLElement>, onClickOutside: VoidFunction) => {
  const clickListener = useCallback(
    ({ target }: MouseEvent) => {
      const clickedItemIsChildOfRefElem = clickOutsideOfMeRef.current?.contains(target as Node);

      if (!clickedItemIsChildOfRefElem) {
        onClickOutside();
      }
    },
    [onClickOutside, clickOutsideOfMeRef]
  );

  useEffect(() => {
    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
     * The callback needs to run before component states update in response to various events.
     * To make sure that happens we are passing in a `true` here to make the callback run on
     * the event capture phase.
     *
     * @example You want to use your callback to close a dropdown when the user clicks outside of it.
     * Clicking a button outside of the dropdown sets your dropdown to open. The callback needs to run
     * before that state changes otherwise it will immediately auto-close your dropdown.
     */
    document.addEventListener("click", clickListener, true);
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [clickListener]);
};

export default useHandleClickOutside;
