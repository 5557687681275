import { Flags } from "../../../features/feature_flags/types";
import { runtimeConfig } from "../../../lib/constants/runtime_config";
import { graphqlApi } from "../graphql_api";
import { getFlagsQuery } from "./constants";
import { CustomAttributes, GetFlagsQueryResult } from "./types";

export const featureFlagsApi = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    getFlags: builder.query<Flags, string | undefined>({
      query: (employeeId?: string) => {
        const variables: CustomAttributes = { platform: "ehr", employeeId };

        if (!runtimeConfig.IS_PRODUCTION) {
          variables.lastFetch = Date.now();
        }

        return {
          document: getFlagsQuery,
          variables,
        };
      },
      transformResponse: ({ featureFlags }: GetFlagsQueryResult) => {
        console.log("🏴‍☠️ Feature flags", featureFlags.list);
        return featureFlags.list;
      },
      providesTags: ["featureFlags"],
    }),
  }),
});

export const { useGetFlagsQuery, useLazyGetFlagsQuery } = featureFlagsApi;
