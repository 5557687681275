import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { resetStore } from "../app/actions";
import { useAppDispatch } from "../app/hooks";
import { anonymize } from "../features/analytics";

const SignOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    anonymize();
    dispatch(resetStore());
    Sentry.setUser(null);
    navigate("/");
  }, [dispatch, navigate]);
  return null;
};

export default SignOut;
