import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { useKeepTokenFresh } from "../features/auth/hooks/use_keep_token_fresh";
import { selectIsSignedIn, selectJwtAuthenticatorId } from "../features/auth/selectors";
import { useFetchAndSyncAllEmployees, useFetchAndSyncCurrentEmployee } from "../features/employees/hooks";
import { selectCurrentEmployee, selectCurrentUser } from "../features/employees/selectors";
import { useFlag, useInitializeFeatureFlags } from "../features/feature_flags/hooks";
import { Initial2AEnrollment } from "./initial_2fa_enrollment";

import Invites from "./invites";
import NotFound from "./not_found";
import { Redirect } from "./redirect";
import SignIn from "./sign_in";
import SignOut from "./sign_out";
import { useInitializeAnalytics } from "../features/analytics";
import { runtimeConfig } from "../lib/constants/runtime_config";
import Text from "@evernowinc/harbard/text";
import HeaderText from "@evernowinc/harbard/header_text";

const Admin = lazy(() => import("./admin"));
const ApiExplorer = lazy(() => import("./api_explorer"));
const Engineering = lazy(() => import("./engineering"));
const FeatureFlagCustomizer = lazy(() => import("./engineering/feature_flag_customizer"));
const ConnectCalendar = lazy(() => import("./connect_calendar"));
const Identities = lazy(() => import("./identities"));
const Orders = lazy(() => import("./orders"));
const Patients = lazy(() => import("./patients"));
const Prescriptions = lazy(() => import("./prescriptions"));
const Profile = lazy(() => import("./profile"));
const Reset = lazy(() => import("./reset"));
const RxErrors = lazy(() => import("./rx_errors"));
const Search = lazy(() => import("./search"));
const Tasks = lazy(() => import("./tasks"));
const Test = lazy(() => import("./test"));
const Tickets = lazy(() => import("./tickets"));
const VideoVisits = lazy(() => import("./video_visits"));

const AppRoutes = () => {
  useKeepTokenFresh();
  useInitializeFeatureFlags();
  useFetchAndSyncAllEmployees();
  const isProduction = runtimeConfig.IS_PRODUCTION;
  const { currentEmployeeIsError, currentEmployeeIsPending } = useFetchAndSyncCurrentEmployee();

  const isSignedIn = useAppSelector(selectIsSignedIn);
  const authenticatorId = useAppSelector(selectJwtAuthenticatorId);
  const currentUser = useAppSelector(selectCurrentUser);
  const currentEmployee = useAppSelector(selectCurrentEmployee);
  const killTheSnoozer = useFlag("kill_the_snoozer");
  const isDowntimeEnabled = useFlag("ehr_downtime");
  const redirectPath = killTheSnoozer ? "/tasks" : "/tickets";

  useInitializeAnalytics(currentUser?.id, currentEmployee?.id);

  const paddedCustomizer = (
    <div className="p-34">
      <FeatureFlagCustomizer />
    </div>
  );

  if (isDowntimeEnabled) {
    return (
      <div className="is-flex is-justify-content-center is-align-items-center" style={{ height: "100vh" }}>
        <div className="box has-background-light p-24" style={{ maxWidth: "500px" }}>
          <HeaderText variant="h3">EHR is temporarily offline</HeaderText>
          <Text className="pt-24">
            The Evernow EHR is currently experiencing a planned downtime while we perform some necessary maintenance. We
            apologize for the interruption and plan to have services restored very soon.
          </Text>
        </div>
      </div>
    );
  }

  if (!isSignedIn) {
    return (
      <Routes>
        <Route element={<Invites />} path="/invites/:code" />
        <Route element={<Reset />} path="/reset/:code" />
        {!isProduction && <Route element={paddedCustomizer} path="/engineering/flags" />}
        <Route element={<SignIn />} path="*" />
      </Routes>
    );
  }

  if (!authenticatorId) {
    return (
      <Routes>
        <Route element={<Initial2AEnrollment />} path="*" />
        {!isProduction && <Route element={paddedCustomizer} path="/engineering/flags" />}
        <Route element={<SignOut />} path="/sign_out" />
      </Routes>
    );
  }

  if (currentEmployeeIsError) return <div>There was a problem logging in - please contact technical support.</div>;

  if (currentEmployeeIsPending || !currentUser) return null;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<Initial2AEnrollment />} path="/qr_scan" />
        <Route element={<ConnectCalendar />} path="/connect_calendar" />
        <Route element={<SignOut />} path="/sign_out" />
        <Route element={<Tickets />} path="/tickets/*" />
        <Route element={<VideoVisits />} path="/video_visits/*" />
        <Route element={<Patients />} path="/patients/*" />
        <Route element={<Tasks />} path="/tasks/*" />
        <Route element={<Profile />} path="/profile" />
        <Route element={<Prescriptions />} path="/prescriptions/*" />
        <Route element={<Admin />} path="/admin/*" />
        <Route element={<Search />} path="/search/*" />
        <Route element={<Orders />} path="/orders/*" />
        <Route element={<Tasks />} path="/inbox/*" />
        <Route element={<Identities />} path="/identities/*" />
        <Route element={<RxErrors />} path="/rx_errors" />
        <Route element={<Redirect to={redirectPath} />} path="/invites/*" />
        <Route element={<Redirect to={redirectPath} />} path="/reset/*" />
        <Route element={<Redirect to={redirectPath} />} path="/" />
        <Route element={<Redirect to={redirectPath} />} path="/sign_in" />

        <Route element={<Engineering />} path="/engineering/*" />

        <Route element={<ApiExplorer />} path="/api_explorer" />
        <Route element={<Test />} path="/test/*" />

        <Route element={<NotFound />} path="*" />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
