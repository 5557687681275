import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useRenewTokenMutation } from "../../../app/services/auth_api";
import { useRefreshableTimeout } from "../../../lib/use_refreshable_timeout";
import { setToken } from "../actions";
import { selectJwtExpiration, selectJwtIsExpired } from "../selectors";
import { anonymize } from "../../analytics";

// Refresh the token 5m before it expires
const REFRESH_TIME_BUFFER = 5 * 60 * 1000;

export const useKeepTokenFresh = () => {
  const dispatch = useAppDispatch();
  const [renew] = useRenewTokenMutation();

  const expiration = useAppSelector(selectJwtExpiration);
  const isExpired = useAppSelector(selectJwtIsExpired);
  const setRefreshTime = useRefreshableTimeout(async () => {
    const { token } = await renew().unwrap();
    if (!token) {
      Sentry.captureException(new Error("UseKeepTokenFresh hook: Token renewal failed"));
      console.warn("Token renewal failed");
      // Return early to avoid setting the token to null or empty string. If a
      // refresh fails, we can continue trying to use the old token until we're
      // forced to log out. This is in case the refresh fails due to a network
      // error or something else that might be temporary.
      return;
    }
    dispatch(setToken(token));
  });

  useEffect(() => {
    if (expiration) {
      if (isExpired) {
        dispatch(setToken(""));
        anonymize();
        console.info("🔒 Expired token found in browser storage");
      } else {
        const refreshIn = Math.max(0, expiration.getTime() - Date.now() - REFRESH_TIME_BUFFER);
        setRefreshTime(refreshIn);

        console.info(`🔒 Token refresh scheduled for ${new Date(Date.now() + refreshIn).toLocaleString()}`);
      }
    }
  }, [dispatch, expiration, isExpired, setRefreshTime]);
};
