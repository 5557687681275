import { graphqlApi, WrappedResponse } from "../graphql_api";
import { recoverUserPasswordMutation, renewTokenMutation, signInMutation } from "./constants";
import { RecoverUserPasswordVars, SignInVars, TokenResponse } from "./types";

export const authApi = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    signIn: builder.mutation<TokenResponse, SignInVars>({
      query: variables => ({
        document: signInMutation,
        variables,
      }),
      transformResponse: ({ signIn }: WrappedResponse<"signIn", TokenResponse>) => signIn,
      invalidatesTags: ["featureFlags"],
    }),
    renewToken: builder.mutation<TokenResponse, void>({
      query: () => ({
        document: renewTokenMutation,
      }),
      transformResponse: ({ renewToken }: WrappedResponse<"renewToken", TokenResponse>) => renewToken,
      invalidatesTags: ["featureFlags"],
    }),
    recoverUserPassword: builder.mutation<TokenResponse, RecoverUserPasswordVars>({
      query: variables => ({
        document: recoverUserPasswordMutation,
        variables,
      }),
      transformResponse: ({ recoverUserPassword }: WrappedResponse<"recoverUserPassword", TokenResponse>) =>
        recoverUserPassword,
    }),
  }),
});

export const { useSignInMutation, useRenewTokenMutation, useRecoverUserPasswordMutation } = authApi;
