import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import * as Sentry from "@sentry/react";
import { getJwtFromToken, getJwtIsExpired } from "../../features/auth/utils";
import { getItem } from "../../features/local_storage/utils";
import { GRAPHQL_ENDPOINT } from "../../lib/constants";

export const graphqlApiTagTypes = [
  "calendarAccess",
  "cronofyMembers",
  "draftMessage",
  "employee",
  "employees",
  "employeeTaskCounts",
  "employeeTasks",
  "featureFlags",
  "finalizedSOAPNoteForObjectId",
  "finalizedSOAPNotesForPatient",
  "medicationSummaries",
  "patientClinicalNotes",
  "patientTags",
  "pharmacyData",
  "prescribingData",
  "ticketLog",
  "uploads",
  "videoVisit",
  "videoVisitsForPatient",
  "patientDocuments",
] as const;
export type GraphqlApiTagType = (typeof graphqlApiTagTypes)[number];

export type WrappedResponse<K extends string, T extends unknown> = {
  [k in K]: T;
};

export interface GraphqlBaseQueryError {
  name: string;
  stack?: string;
  message?: string;
  errorCode?: any;
}

const baseQuery = graphqlRequestBaseQuery({
  url: GRAPHQL_ENDPOINT,
  prepareHeaders: (headers: Headers) => {
    const token = getItem<string>("token");

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
  customErrors: ({ name, stack, response }): GraphqlBaseQueryError => {
    const e = response?.errors?.[0];
    const message = e?.message;
    const errorCode = e?.extensions?.code;

    if (["NOT_AUTHORIZED", "UNAUTHENTICATED"].includes(errorCode)) {
      const token = getItem<string>("token");
      const jwt = getJwtFromToken(token);
      // if the token is not expired, we should not be getting this error
      if (token && !getJwtIsExpired(jwt)) {
        Sentry.captureException(new Error(`GraphQlApi: Unexpected graphQL ${errorCode} error. Token is not expired.`), {
          extra: { error: e },
        });
      }
    }

    return { name, stack, message, errorCode };
  },
});

export const graphqlApi = createApi({
  reducerPath: "graphqlApi",
  baseQuery,
  tagTypes: graphqlApiTagTypes,
  endpoints: () => ({}),
});
