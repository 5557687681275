import * as Sentry from "@sentry/react";
import { FormEvent, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { useSignInMutation } from "../app/services/auth_api";
import { GraphqlBaseQueryError } from "../app/services/graphql_api";
import { setToken } from "../features/auth/actions";

const SignIn = () => {
  const dispatch = useAppDispatch();
  const [signIn] = useSignInMutation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [authenticator, setAuthenticator] = useState<string>("");
  const [error, setError] = useState("");

  const handleSubmit = async (e?: FormEvent) => {
    e && e.preventDefault();
    setError("");

    try {
      const { token } = await signIn({ email, password, authenticator }).unwrap();
      if (token) {
        dispatch(setToken(token));
        Sentry.setUser({ email });
      } else {
        setError(
          "The credentials that you entered are not correct or the authenticator password expired. Please try again."
        );
      }
    } catch (err) {
      const { message } = err as GraphqlBaseQueryError;
      setError(message || "");
    }
  };

  return (
    <div className="section">
      <div className="container" style={{ maxWidth: "375px" }}>
        <div className="box">
          <h1 className="title has-text-centered">Evernow EHR Login</h1>
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  data-testid="email"
                  placeholder="yourname@evernow.com"
                  value={email}
                  autoFocus
                  autoComplete="email"
                  onChange={e => setEmail(e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  data-testid="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={e => setPassword(e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Authenticator Password</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  data-testid="authenticator"
                  pattern="\d*"
                  value={authenticator}
                  autoComplete="one-time-code"
                  onChange={e => setAuthenticator(e.currentTarget.value)}
                />
              </div>
            </div>

            <div className="field">
              <div className="control has-text-centered" style={{ marginTop: "2rem" }}>
                <button className="button is-link" type="submit">
                  Log In
                </button>
              </div>
            </div>

            {error && (
              <div data-testid="login-error" className="help is-danger">
                {error}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
