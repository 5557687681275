import { AppThunk } from "../../app/store";
import { employeesSlice } from "./slice";
import { Employee } from "./types";

const { actions } = employeesSlice;

export const setCurrentEmployee =
  (employee: Employee): AppThunk =>
  dispatch => {
    dispatch(actions.setCurrentEmployee(employee));
  };

export const setEmployees =
  (employees: Employee[]): AppThunk =>
  dispatch => {
    dispatch(actions.setEmployees(employees));
  };
