import { parsePhoneNumberFromString } from "libphonenumber-js";

export const isValidEmail = (address: string | any) => /^\S+@\S+\.\S+$/.test(address);

export const isValidPhone = (phone: string) => {
  // return !!phone.match(/^\+?(1-?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/)?.length;
  const parsedPhone = parsePhoneNumberFromString(phone, "US");
  // new validation criteria from dosespot 8/6/21:
  // - Area code can't start with 0, 1, or be 555
  // - Number cannot contain 7 or more repeating digits
  return (
    !!parsedPhone &&
    parsedPhone.nationalNumber.length === 10 &&
    !parsedPhone.nationalNumber.startsWith("555") &&
    !parsedPhone.nationalNumber.startsWith("1") &&
    !parsedPhone.nationalNumber.startsWith("0") &&
    !parsedPhone.nationalNumber.match(/(\d)\1{6,}/)
  );
};

export const isValidZip = (zip: string) => (zip || "").match(/^\d{5}(?:[-\s]\d{4})?$/) != null;

export const isValidNpi = (npi: string) => {
  // adapted from http://jsfiddle.net/0x4mghyc/
  let tmp,
    sum,
    i = npi.length,
    j;
  i = npi.length;
  if (i === 15 && npi.indexOf("80840", 0) === 0) sum = 0;
  else if (i === 10) sum = 24;
  else return false;
  j = 0;
  while (i !== 0) {
    tmp = npi.charCodeAt(i - 1) - "0".charCodeAt(0);
    if (j++ % 2 !== 0) {
      if ((tmp <<= 1) > 9) {
        tmp -= 10;
        tmp++;
      }
    }
    sum += tmp;
    i--;
  }
  if (sum % 10 === 0) return true;
  else return false;
};

export const isValidDea = (dea: string) => {
  // https://www.pharmacy-tech-study.com/dea-number-verification.html
  const [, letters, numbers] = dea.match(/([A-Za-z]+)(\d+)/) || [];
  if (!letters || !numbers) return false;
  const checkDigit = numbers[numbers.length - 1];
  const a = [1, 3, 5].reduce((acc, current) => acc + parseInt(numbers[current - 1]), 0);
  const b = [2, 4, 6].reduce((acc, current) => acc + parseInt(numbers[current - 1]), 0) * 2;
  const calculatedCheckDigit = (a + b).toString()[1];
  return checkDigit === calculatedCheckDigit;
};
