import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getItem } from "../local_storage/utils";
import { getJwtExpiration, getJwtFromToken, getJwtIsExpired } from "./utils";

export const selectAuth = ({ auth }: RootState) => auth;

export const selectToken = createSelector(selectAuth, () => getItem<string>("token") ?? "");
export const selectJwt = createSelector(selectToken, getJwtFromToken);
export const selectIsSignedIn = createSelector(selectJwt, jwt => !!jwt);
export const selectJwtUserId = createSelector(selectJwt, jwt => jwt?.sub);
export const selectJwtExpiration = createSelector(selectJwt, getJwtExpiration);
export const selectJwtIsExpired = createSelector(selectJwt, getJwtIsExpired);
export const selectJwtAuthenticatorId = createSelector(selectJwt, jwt => jwt?.authenticatorId);
