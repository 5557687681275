import getTheme from "@evernowinc/harbard/themes/evernow";
import { useMemo } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ehrColors from "../layout/_colors.scss";
import type { AppDispatch, RootState } from "./store";

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useEhrHarbardTheme = () => {
  return useMemo(() => {
    return getTheme({
      overrideHtmlFontStyles: false,
      baseSize: 14,
      mobileHtmlFontSize: 14,
      overridePalette: {
        background: {
          default: ehrColors.schemeMain,
          paper: ehrColors.background,
        },
        divider: ehrColors.hrBackgroundColor,
        primary: {
          main: ehrColors.primary,
          light: ehrColors.primaryLight,
          dark: ehrColors.primaryDark,
        },
        success: {
          main: ehrColors.success,
        },
        error: {
          main: ehrColors.danger,
        },
        warning: {
          main: ehrColors.warningDarker,
          light: ehrColors.warning,
        },
      },
    });
  }, []);
};
