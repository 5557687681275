import { gql, useQuery } from "@apollo/react-hooks";
import { useAppSelector } from "../app/hooks";
import { selectCurrentUserPermissions } from "../features/employees/selectors";

const useRxErrorCount = () => {
  const { isProvider } = useAppSelector(selectCurrentUserPermissions);
  const { data } = useQuery<{ dosespot: { errorCount: number } }>(
    gql`
      query {
        dosespot {
          errorCount
        }
      }
    `,
    { pollInterval: 120000, skip: !isProvider }
  );
  return data?.dosespot?.errorCount ?? 0;
};

export default useRxErrorCount;
