import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Employee, EmployeeStoreState } from "./types";

export const initialState: EmployeeStoreState = {
  currentEmployee: {} as Employee,
  employees: [],
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setCurrentEmployee: (state, action: PayloadAction<Employee>) => {
      state.currentEmployee = action.payload;
    },
    setEmployees: (state, action: PayloadAction<Employee[]>) => {
      state.employees = action.payload;
    },
  },
});

export default employeesSlice.reducer;
