import { ApolloError, gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { createContext, FormEvent, ReactElement, useContext, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch } from "../app/hooks";
import { setToken } from "../features/auth/actions";
import { UserRole } from "../features/employees/types";
import { isValidDea, isValidEmail, isValidNpi } from "../lib/validators";

const QUERY = gql`
  query employeeInvite($code: String!) {
    employeeInvite(code: $code) {
      email
      expiresAt
      roles
    }
  }
`;

const ACTIVATE_EMPLOYEE = gql`
  mutation activateEmployee($data: ActivateEmployeeInput!) {
    activateEmployee(data: $data) {
      token
    }
  }
`;

const ACTIVATE_PROVIDER = gql`
  mutation activateProvider($data: ActivateProviderInput!) {
    activateProvider(data: $data) {
      token
    }
  }
`;

const NotValid = () => <div>Sorry, that&rsquo;s not a valid code.</div>;

type Invite = {
  email: string;
  expiresAt: Date;
  roles: UserRole[];
  code: string;
};

const InviteContext = createContext<Invite | null>(null);

const CodeValidator = ({ code, children }: { code: string | null | undefined; children: ReactElement }) => {
  const { data, loading, error } = useQuery<{ employeeInvite: Invite | null }, { code: string | null | undefined }>(
    QUERY,
    { variables: { code }, skip: !code }
  );

  if (!code) return <NotValid />;

  if (loading || !data) return null;

  if (error) throw new Error(error.message);

  if (data.employeeInvite) {
    return <InviteContext.Provider value={{ ...data.employeeInvite, code }}>{children}</InviteContext.Provider>;
  } else {
    return <NotValid />;
  }
};

const InviteForm = () => {
  const invite = useContext(InviteContext);
  if (!invite) throw new Error("No invite");

  const dispatch = useAppDispatch();

  const [mutationEmployee] = useMutation<{ activateEmployee: { token: string } }, { data: any }>(ACTIVATE_EMPLOYEE);
  const [mutationProvider] = useMutation<{ activateProvider: { token: string } }, { data: any }>(ACTIVATE_PROVIDER);
  const client = useApolloClient();

  const [givenName, setGivenName] = useState<string>("");
  const [familyName, setFamilyName] = useState<string>("");
  const [email, setEmail] = useState<string>(invite.email);
  const [password, setPassword] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [npi, setNpi] = useState<string>("");
  const [dea, setDea] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [credentials, setCredentials] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [location, setLocation] = useState<string>("");

  const [errors, setErrors] = useState<string | null>(null);

  const isProvider = invite.roles.includes("PROVIDER");
  const isValid =
    !!givenName &&
    !!familyName &&
    isValidEmail(email) &&
    !!password &&
    (isProvider ? !!dob : true) &&
    (isProvider ? isValidNpi(npi) : true) &&
    (dea ? isValidDea(dea) : true);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const variables = {
        data: {
          givenName,
          familyName,
          email,
          password,
          code: invite.code,
          ...(isProvider
            ? {
                npi,
                dateOfBirth: dob,
                dea,
                description,
                title,
                credentials,
                phone,
                location,
              }
            : {}),
        },
      };

      const result = await (isProvider ? mutationProvider({ variables }) : mutationEmployee({ variables }));

      if (result.data) {
        await client.cache.reset();
        const token =
          "activateProvider" in result.data ? result.data.activateProvider.token : result.data.activateEmployee.token;
        dispatch(setToken(token));
      }
    } catch (err) {
      const { graphQLErrors, message } = err as ApolloError;
      if (graphQLErrors) {
        return setErrors(graphQLErrors.map(e => e.message).join("\n"));
      }
      setErrors(message);
    }
  };

  return (
    <div className="section">
      <div className="container" style={{ maxWidth: "500px" }}>
        <form onSubmit={onSubmit}>
          <h2 className="is-size-3 has-text-weight-bold" style={{ margin: "2rem 0 2rem 0" }}>
            Welcome to Evernow Care
          </h2>
          <p style={{ marginBottom: "1rem" }}>
            We&rsquo;ll have you up and running inside Evernow Care within a few minutes. To start, complete this form.
          </p>

          <div className="field">
            <div className="label">Given Name</div>
            <div className="control">
              <input
                className="input"
                placeholder="Buffy"
                autoComplete="given-name"
                autoFocus
                value={givenName}
                onChange={e => setGivenName(e.currentTarget.value)}
              />
            </div>
          </div>

          <div className="field">
            <div className="label">Family Name</div>
            <div className="control">
              <input
                className="input"
                placeholder="Summers"
                autoComplete="family-name"
                value={familyName}
                onChange={e => setFamilyName(e.currentTarget.value)}
              />
            </div>
          </div>

          <div className="field">
            <div className="label">Email</div>
            <div className="control">
              <input
                className="input"
                placeholder="bsummers@evernow.com"
                autoComplete="email"
                autoCapitalize="off"
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
              />
            </div>
          </div>

          <div className="field">
            <div className="label">Password</div>
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                value={password}
                onChange={e => setPassword(e.currentTarget.value)}
              />
            </div>
          </div>

          {isProvider && (
            <>
              <div className="field">
                <div className="label">Title</div>
                <div className="control">
                  <input
                    className="input"
                    placeholder="Dr."
                    autoComplete="off"
                    autoCapitalize="off"
                    value={title}
                    onChange={e => setTitle(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="field">
                <div className="label">Credentials</div>
                <div className="control">
                  <input
                    className="input"
                    placeholder="MD, FACOG"
                    autoComplete="off"
                    autoCapitalize="off"
                    value={credentials}
                    onChange={e => setCredentials(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="field">
                <div className="label">Biography</div>
                <div className="control">
                  <textarea
                    spellCheck
                    className="textarea"
                    value={description}
                    onChange={e => setDescription(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="field">
                <div className="label">Date of birth</div>
                <div className="control">
                  <input type="date" className="input" value={dob} onChange={e => setDob(e.currentTarget.value)} />
                </div>
              </div>
              <div className="field">
                <div className="label">NPI</div>
                <div className="control">
                  <input
                    className="input"
                    placeholder="1558444216"
                    autoComplete="off"
                    autoCapitalize="off"
                    value={npi}
                    onChange={e => setNpi(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="field">
                <div className="label">DEA</div>
                <div className="control">
                  <input
                    className="input"
                    placeholder="BJ6125341"
                    autoComplete="off"
                    autoCapitalize="off"
                    value={dea}
                    onChange={e => setDea(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div className="field">
                <div className="label">Phone</div>
                <div className="control">
                  <input
                    className="input"
                    placeholder="(510) 806-7890"
                    autoComplete="phone"
                    autoCapitalize="off"
                    value={phone}
                    onChange={e => setPhone(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div className="field">
                <div className="label">Location</div>
                <div className="control">
                  <input
                    className="input"
                    placeholder="San Francisco, CA"
                    autoComplete="off"
                    autoCapitalize="off"
                    value={location}
                    onChange={e => setLocation(e.currentTarget.value)}
                  />
                </div>
              </div>
            </>
          )}

          {errors && (
            <div className="notification is-danger" style={{ whiteSpace: "pre-line" }}>
              {errors}
            </div>
          )}

          <div className="field">
            <button className="button is-primary" disabled={!isValid}>
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Invites = () => {
  const { code } = useParams();
  return (
    <CodeValidator code={code}>
      <InviteForm />
    </CodeValidator>
  );
};

export default Invites;
