import { gql, useMutation } from "@apollo/client";
import * as React from "react";
import { usePageVisibility } from "react-page-visibility";
import { v4 as uuid } from "uuid";
import { useInterval } from "./use_interval";

const SEGMENT_DURATION_SECONDS = 10;
const MAX_REPORTABLE_SEGMENT_SECONDS = 60;

type TicketTimerContext = {
  start: (ticketId: string) => any;
  end: (ticketId: string) => any;
};

const TicketTimerContext = React.createContext<TicketTimerContext>({ start: () => null, end: () => null });

type Segment = {
  startTime: number;
};

export const TicketTimerContextProvider = (props: { children: any }) => {
  const segment = React.useRef<Segment | null>(null);
  const ticketId = React.useRef<string | null>(null);
  const sessionId = React.useRef<string | null>(null);

  const isVisible = usePageVisibility();

  const [recordTicketTime] = useMutation<{ ticketId: string; duration: string; sessionId: string | null }>(
    gql`
      mutation recordTicketTime($ticketId: ID!, $duration: Int!, $sessionId: String) {
        recordTicketTime(ticketId: $ticketId, duration: $duration, sessionId: $sessionId)
      }
    `,
    { ignoreResults: true }
  );

  const startSegment = () => {
    segment.current = {
      startTime: Date.now(),
    };
  };

  const endSegment = React.useCallback(() => {
    if (!segment.current) return null;

    const duration = Math.round(
      Math.min(Math.max(Date.now() - segment.current.startTime, 0) / 1000, MAX_REPORTABLE_SEGMENT_SECONDS)
    );

    recordTicketTime({ variables: { ticketId: ticketId.current, duration, sessionId: sessionId.current } }).then(() => {
      console.info(`⏰ ${ticketId.current}: ${duration}s segment persisted`);
    });

    segment.current = null;
  }, [recordTicketTime]);

  const start = (id: string) => {
    if (segment.current) {
      endSegment();
    }
    startSegment();
    sessionId.current = uuid();
    ticketId.current = id;
    console.info(`⏰ ${ticketId.current}: tracking started`);
  };

  const end = async (id: string) => {
    if (segment.current && ticketId.current === id) {
      endSegment();
      console.info(`⏰ ${ticketId.current}: tracking ended`);
    }
  };

  React.useEffect(() => {
    if (ticketId.current) {
      if (segment.current) {
        if (!isVisible) {
          console.info(`⏰ ${ticketId.current}: offscreen, ending segment`);
          endSegment();
        }
      } else if (isVisible && !segment.current) {
        console.info(`⏰ ${ticketId.current}: onscreen, resuming tracking`);
        startSegment();
      }
    }
  }, [segment, ticketId, isVisible, endSegment]);

  useInterval(() => {
    if (segment.current) {
      if (Date.now() - segment.current.startTime > SEGMENT_DURATION_SECONDS * 1000) {
        endSegment();
        startSegment();
      }
    }
  }, 500);

  return <TicketTimerContext.Provider value={{ start, end }}>{props.children}</TicketTimerContext.Provider>;
};

export const useTicketTimerContext = () => React.useContext(TicketTimerContext);
