import AccountTree from "@evernowinc/harbard-icons/AccountTree";
import AssignmentLate from "@evernowinc/harbard-icons/AssignmentLate";
import ChevronLeft from "@evernowinc/harbard-icons/ChevronLeft";
import ChevronRight from "@evernowinc/harbard-icons/ChevronRight";
import Engineering from "@evernowinc/harbard-icons/Engineering";
import Group from "@evernowinc/harbard-icons/Group";
import Home from "@evernowinc/harbard-icons/Home";
import Person from "@evernowinc/harbard-icons/Person";
import Pill from "@evernowinc/harbard-icons/Pill";
import Search from "@evernowinc/harbard-icons/Search";
import ShieldPerson from "@evernowinc/harbard-icons/ShieldPerson";
import Task from "@evernowinc/harbard-icons/Task";
import Box from "@evernowinc/harbard/box";
import Flex, { FlexChild } from "@evernowinc/harbard/flex";
import useColors from "@evernowinc/harbard/hooks/use_colors";
import IconButton from "@evernowinc/harbard/icon_button";
import Logo from "@evernowinc/harbard/logo";
import classnames from "classnames";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { selectCurrentUserPermissions } from "../features/employees/selectors";
import { useFlag } from "../features/feature_flags/hooks";
import useStoredBoolean from "../features/local_storage/hooks/use_stored_boolean";
import logoIcon from "../images/logo_icon.svg";
import { runtimeConfig } from "../lib/constants/runtime_config";
import useRxErrorCount from "../lib/use_rx_error_count";
import { SMALL_SIDEBAR_SECTION_HEIGHT } from "./constants";
import ProfileSidebarItem from "./profile_sidebar_item";
import SidebarItem, { SidebarItemProps } from "./sidebar_item";

export const useStyles = () => {
  const colors = useColors();

  return useMemo(() => {
    return {
      sidebarContainer: {
        backgroundColor: colors.primary.light,
        height: "100%",
        position: "relative",
      },
      logoContainer: {
        height: SMALL_SIDEBAR_SECTION_HEIGHT,
        borderBottom: `1px solid ${colors.divider}`,
      },
      toggleButton: {
        border: `1px solid ${colors.divider}`,
        borderRadius: "100%",
        color: colors.grey[500],
        background: "white",
        width: "24px",
        height: "24px",
        position: "absolute",
        top: "28px",
        right: "-12px",
        zIndex: 31,
      },
      navContainer: {
        overflowY: "auto",
      },
      listItems: {
        listStyle: "none",
      },
    };
  }, [colors]);
};

const LayoutSidebar = memo(() => {
  const [sidebarIsOpen, openSidebar, closeSidebar] = useStoredBoolean("sidebar_expanded");
  const { isAdmin, isProvider, isEngineer } = useAppSelector(selectCurrentUserPermissions);
  const rxErrorCount = useRxErrorCount();
  const styles = useStyles();
  const shouldShowTickets = !useFlag("kill_the_snoozer");

  const navList = useMemo<SidebarItemProps["item"][]>(() => {
    const items: (SidebarItemProps["item"] | false)[] = [
      { title: "Worklists", link: "/tasks", iconElement: <Home /> },
      shouldShowTickets && { title: "Tickets", link: "/tickets", iconElement: <Task /> },
      { title: "Patients", link: "/patients", iconElement: <Group /> },
      { title: "Rx Orders", link: "/orders", iconElement: <Pill /> },
      { title: "Identities", link: "/identities", iconElement: <Person /> },
      { title: "Search", link: "/search", iconElement: <Search /> },
      isProvider && {
        title: "Rx Errors",
        link: "/rx_errors",
        iconElement: <AssignmentLate />,
        notificationCount: rxErrorCount ?? 0,
      },
      isAdmin && { title: "Admin", link: "/admin", iconElement: <ShieldPerson /> },
      isEngineer && { title: "Engineering", link: "/engineering", iconElement: <Engineering /> },
      isAdmin &&
        !runtimeConfig.IS_PRODUCTION && { title: "API Explorer", link: "/api_explorer", iconElement: <AccountTree /> },
    ].filter(Boolean);

    return items as SidebarItemProps["item"][];
  }, [shouldShowTickets, isProvider, rxErrorCount, isAdmin, isEngineer]);

  return (
    <Flex
      alignItems="stretch"
      className="sidebar-container"
      flexDirection="column"
      flexWrap="nowrap"
      sx={styles.sidebarContainer}>
      <Box
        className={classnames("logo-container", sidebarIsOpen ? "pt-26 pb-14 px-30" : "pt-16 pb-3 px-20")}
        sx={styles.logoContainer}>
        <Link to="/">{sidebarIsOpen ? <Logo /> : <img alt="Evernow logo" src={logoIcon} width="40px" />}</Link>
      </Box>
      <IconButton
        aria-hidden="true"
        className="toggle-button"
        iconElement={sidebarIsOpen ? <ChevronLeft /> : <ChevronRight />}
        onClick={sidebarIsOpen ? closeSidebar : openSidebar}
        sx={styles.toggleButton}
      />
      <FlexChild flexGrow={1} sx={styles.navContainer}>
        <Box component="nav">
          <Box className="pt-30" component="ul" sx={styles.listItems}>
            {navList.map(item => (
              <SidebarItem item={item} key={item.title} sidebarIsOpen={sidebarIsOpen} />
            ))}
          </Box>
        </Box>
      </FlexChild>
      <ProfileSidebarItem sidebarIsOpen={sidebarIsOpen} />
    </Flex>
  );
});

LayoutSidebar.displayName = "LayoutSidebar";
export default LayoutSidebar;
