import { useCallback } from "react";
import { useStorageContext } from "../storage_context";
import { LocalStorageKey } from "../types";

const useStoredState = <T>(key: LocalStorageKey): [T, (val: T) => void] => {
  const { getStoredState, setStoredState } = useStorageContext();

  const value = getStoredState<T>(key);
  if (value === null) {
    throw new Error(
      `No value found for localStorage key: \`${key}\`. Did you forget to pass a default to the Provider?`
    );
  }

  const setValue = useCallback(<T>(value: T) => setStoredState(key, value), [key, setStoredState]);

  return [value, setValue];
};

export default useStoredState;
