import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isStoreResetAction } from "../../app/actions";
import { removeItem, setItem } from "../local_storage/utils";
import { AuthStoreState } from "./types";

export const initialState: AuthStoreState = {
  tokenUpdatedAt: Date.now(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // We track the time the token is updated for the purposes of triggering subscriptions to
    // state changes but the token itself actually lives in local storage so it can be shared
    // by multiple tabs.
    setToken: (state, action: PayloadAction<string>) => {
      state.tokenUpdatedAt = Date.now();
      setItem("token", action.payload);
    },
  },
  // This will remove the token from local storage when the store is reset.
  extraReducers: builder => {
    builder.addMatcher(isStoreResetAction, state => {
      state.tokenUpdatedAt = Date.now();
      removeItem("token");
    });
  },
});

export default authSlice.reducer;
