import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentSubmission, DocumentsStoreState } from "./types";
import { UploadWithUrl } from "../../app/services/timeline_api/types";

export const initialState: DocumentsStoreState = {
  documentSubmissions: [],
  selectedSubmissionId: null,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocumentSubmissions: (state, action: PayloadAction<DocumentSubmission[]>) => {
      state.documentSubmissions = action.payload;
    },
    setSelectedSubmissionId: (state, action: PayloadAction<string | null>) => {
      state.selectedSubmissionId = action.payload;
    },
    refreshSubmissionUrls: (state, action: PayloadAction<{ submissionId: string; uploads: UploadWithUrl[] }>) => {
      const { submissionId, uploads } = action.payload;
      const submission = state.documentSubmissions.find(({ id }) => id === submissionId);

      if (submission) {
        submission.documents.forEach(document => {
          const upload = uploads.find(({ uploadId }) => uploadId === document.id);

          if (upload) {
            document.url = upload.url;
          }
        });
      }
    },
  },
});

export default documentsSlice.reducer;
