import ExpandLess from "@evernowinc/harbard-icons/ExpandLess";
import ExpandMore from "@evernowinc/harbard-icons/ExpandMore";
import Logout from "@evernowinc/harbard-icons/Logout";
import Settings from "@evernowinc/harbard-icons/Settings";
import Flex, { FlexChild } from "@evernowinc/harbard/flex";
import HarbardIcon from "@evernowinc/harbard/harbard_icon";
import useColors from "@evernowinc/harbard/hooks/use_colors";
import Text from "@evernowinc/harbard/text";
import { memo, useCallback, useContext, useMemo } from "react";
import { useAppSelector } from "../app/hooks";
import Avatar from "../components/avatar";
import DropdownContentList, { DropdownContentListItem } from "../components/dropdown_content_list";
import {
  selectCurrentEmployeeAvatarUrl,
  selectCurrentEmployeeDisplayName,
  selectCurrentEmployeeGivenName,
} from "../features/employees/selectors";
import { Dropdown, DropdownContext } from "../lib/dropdown";
import { SMALL_SIDEBAR_SECTION_HEIGHT } from "./constants";

const useSxStyles = () => {
  const colors = useColors();
  return useMemo(() => {
    return {
      avatarClickTarget: {
        height: SMALL_SIDEBAR_SECTION_HEIGHT,
        borderTop: `1px solid ${colors.divider}`,
        cursor: "pointer",
      },
    };
  }, [colors]);
};

interface ProfileSidebarItemContentProps {
  sidebarIsOpen: boolean;
}

const ProfileSidebarItemContent = memo(({ sidebarIsOpen }: ProfileSidebarItemContentProps) => {
  const { active: dropdownIsOpen, setActive } = useContext(DropdownContext);

  const avatarUrl = useAppSelector(selectCurrentEmployeeAvatarUrl);
  const displayName = useAppSelector(selectCurrentEmployeeDisplayName);
  const givenName = useAppSelector(selectCurrentEmployeeGivenName);

  const sxStyles = useSxStyles();

  const toggleDropdown = useCallback(() => setActive(!dropdownIsOpen), [dropdownIsOpen, setActive]);

  const contentItems: DropdownContentListItem[] = useMemo(() => {
    return [
      {
        iconElement: <Settings />,
        label: "Settings",
        linkTo: "/profile",
      },
      {
        iconElement: <Logout />,
        label: "Sign out",
        linkTo: "/sign_out",
      },
    ];
  }, []);

  return (
    <FlexChild data-testid="profile-trigger" flexGrow={1} onClick={toggleDropdown}>
      <Flex
        component="div"
        className="px-20"
        sx={sxStyles.avatarClickTarget}
        alignContent="center"
        justifyContent="flex-start"
        flexWrap="nowrap"
        gap="xs">
        <Avatar size="40px" disableTooltip src={avatarUrl} name={displayName} />
        {sidebarIsOpen && (
          <>
            <Text className="ml-4" component="span" variant="subtitle1">
              {givenName}
            </Text>
            <HarbardIcon iconElement={dropdownIsOpen ? <ExpandMore /> : <ExpandLess />} />
          </>
        )}
      </Flex>
      <DropdownContentList items={contentItems} />
    </FlexChild>
  );
});

ProfileSidebarItemContent.displayName = "ProfileSidebarItemContent";

const ProfileSidebarItem = (props: ProfileSidebarItemContentProps) => {
  return (
    <Dropdown.Container isUp xOffset="38px" yOffset="12px">
      <ProfileSidebarItemContent {...props} />
    </Dropdown.Container>
  );
};

export default ProfileSidebarItem;
