import { graphqlApi } from "../graphql_api";
import {
  employeeQuery,
  employeesQuery,
  employeeWithAdminFieldsQuery,
  setUserRolesMutation,
  updateEmployeeMutation,
} from "./constants";
import {
  EmployeeQueryResult,
  EmployeeQueryVariables,
  EmployeesQueryResult,
  EmployeesQueryVariables,
  EmployeeWithAdminFieldsQueryResult,
  SetUserRolesMutationVariables,
  UpdateEmployeeMutationVariables,
} from "./types";

export const employeesApi = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    getEmployee: builder.query<EmployeeQueryResult["employee"], EmployeeQueryVariables | void>({
      query: variables => ({
        document: employeeQuery,
        variables,
      }),
      transformResponse: ({ employee }: EmployeeQueryResult) => {
        return employee;
      },
      providesTags: ["employee"],
    }),

    getEmployees: builder.query<EmployeesQueryResult["employees"], EmployeesQueryVariables | void>({
      query: variables => ({
        document: employeesQuery,
        variables,
      }),
      transformResponse: ({ employees }: EmployeesQueryResult) => {
        return employees;
      },
      providesTags: ["employees"],
    }),

    getEmployeeWithAdminFields: builder.query<
      EmployeeWithAdminFieldsQueryResult["employee"],
      EmployeeQueryVariables | void
    >({
      query: variables => ({
        document: employeeWithAdminFieldsQuery,
        variables,
      }),
      transformResponse: ({ employee }: EmployeeWithAdminFieldsQueryResult) => {
        return employee;
      },
      providesTags: ["employee"],
    }),

    setUserRoles: builder.mutation<void, SetUserRolesMutationVariables>({
      query: variables => ({
        document: setUserRolesMutation,
        variables,
      }),
      invalidatesTags: ["employee", "employees"],
    }),

    updateEmployee: builder.mutation<void, UpdateEmployeeMutationVariables>({
      query: variables => ({
        document: updateEmployeeMutation,
        variables: { data: variables },
      }),
      invalidatesTags: ["employee", "employees"],
    }),
  }),
});

export const {
  useGetEmployeeQuery,
  useGetEmployeesQuery,
  useGetEmployeeWithAdminFieldsQuery,
  useSetUserRolesMutation,
  useUpdateEmployeeMutation,
} = employeesApi;
