import { useMemo } from "react";
import { runtimeConfig } from "../../../lib/constants/runtime_config";
import { useStorageContext } from "../../local_storage/storage_context";

/**
 * Fetch the values of the local storage overrides for feature flags
 * @param storeKey
 * @returns
 */
export const useGetFlagOverrides = () => {
  const { getStoredState } = useStorageContext();

  const featureFlagOverrides = useMemo(() => {
    const overrides = getStoredState("featureFlagOverrides");
    if (overrides === null) return {};
    if (typeof overrides === "object") {
      return overrides;
    } else {
      !runtimeConfig.IS_PRODUCTION &&
        console.log("❗ featureFlagOverrides value not valid. It will be ignored:", overrides);
    }
  }, [getStoredState]);

  return featureFlagOverrides ?? {};
};
