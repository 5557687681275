import { AnyAction, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "../features/auth/slice";
import documentsReducer from "../features/documents/slice";
import employeesReducer from "../features/employees/slice";
import employeeTasksReducer from "../features/employee_tasks/slice";
import featureFlagsReducer from "../features/feature_flags/slice";
import { isStoreResetAction } from "./actions";
import { graphqlApi } from "./services/graphql_api";

// Reducer key name must match the feature's slice.name
const rootReducer = combineReducers({
  auth: authReducer,
  documents: documentsReducer,
  employees: employeesReducer,
  employeeTasks: employeeTasksReducer,
  featureFlags: featureFlagsReducer,
  [graphqlApi.reducerPath]: graphqlApi.reducer,
});

const resettableRootReducer = (state: ReturnType<typeof rootReducer> | undefined, action: AnyAction) => {
  if (isStoreResetAction(action)) {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(graphqlApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector<T> = (state: RootState) => T;
// For async thunks, type your thunk with AppThunk<Promise<MyReturnType>>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
