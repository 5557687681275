import { gql } from "graphql-request";

export const signInMutation = gql`
  mutation signIn($email: String!, $password: String!, $authenticator: String) {
    signIn(email: $email, password: $password, authenticator: $authenticator) {
      token
    }
  }
`;

export const renewTokenMutation = gql`
  mutation {
    renewToken {
      token
    }
  }
`;

export const recoverUserPasswordMutation = gql`
  mutation recoverUserPassword($code: String!, $password: String!, $authenticator: String) {
    recoverUserPassword(code: $code, password: $password, authenticator: $authenticator) {
      token
    }
  }
`;
