import Layout from "../layout";

type Props = {
  message?: string;
};

const NotFound = ({ message }: Props) => (
  <Layout>
    <h1 className="title">Not Found</h1>
    {message && <p>{message}</p>}
  </Layout>
);

export default NotFound;
