import Box from "@evernowinc/harbard/box";
import Flex, { FlexChild } from "@evernowinc/harbard/flex";
import useColors from "@evernowinc/harbard/hooks/use_colors";
import { ReactNode, useMemo } from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "../app/hooks";
import { DosespotRegistrationModal } from "../components/dosespot/dosespot_registration_modal";
import { selectCurrentEmployee, selectCurrentUserPermissions } from "../features/employees/selectors";
import { TicketTimerContextProvider } from "../lib/ticket_timer_context";
import { MAIN_CONTENT_PADDING } from "./constants";
import LayoutSidebar from "./sidebar";
import "./styles.scss";

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props) => {
  const currentEmployee = useAppSelector(selectCurrentEmployee);
  const { isProvider } = useAppSelector(selectCurrentUserPermissions);

  // Matches a pattern like: /tickets/SOMETHING_AFTER_THE_SLASH
  const shouldHaveContrastingBg = /\/(tickets|video_visits)\/[^\s]+/.test(useLocation().pathname);
  const colors = useColors();

  const layoutStyles = useMemo(() => {
    return {
      layoutContainer: {
        height: "100vh",
        overflow: "hidden",
      },
      contentColumn: {
        position: "relative",
        overflowY: "auto",
        backgroundColor: shouldHaveContrastingBg ? colors.background.paper : colors.background.default,
      },
      contentChildren: {
        padding: `${MAIN_CONTENT_PADDING}px`,
      },
    };
  }, [colors, shouldHaveContrastingBg]);

  if (!currentEmployee) return null;

  return (
    <TicketTimerContextProvider>
      <>
        {isProvider && <DosespotRegistrationModal />}

        <Flex
          className="layout-container"
          flexDirection="row"
          flexWrap="nowrap"
          alignContent="stretch"
          alignItems="stretch"
          sx={layoutStyles.layoutContainer}>
          <FlexChild flexGrow={0}>
            <LayoutSidebar />
          </FlexChild>
          <FlexChild flexGrow={1} sx={layoutStyles.contentColumn} id="scrollable-content">
            <Box sx={layoutStyles.contentChildren}>{children}</Box>
          </FlexChild>
        </Flex>
      </>
    </TicketTimerContextProvider>
  );
};

export default Layout;
