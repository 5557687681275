import classnames from "classnames";
import {
  ButtonHTMLAttributes,
  createContext,
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import useHandleClickOutside from "./use_handle_click_outside";

export const DropdownContext = createContext<{
  active: boolean;
  hoverable: boolean;
  setActive: (value: boolean) => any;
  menuRef: RefObject<HTMLDivElement>;
  config: {
    disableAutoCloseTimeout?: boolean;
    disableCloseOnClickInsideMenu?: boolean;
    isUp?: boolean;
    isRight?: boolean;
    xOffset?: string;
    yOffset?: string;
  };
}>({
  active: false,
  hoverable: false,
  setActive: () => null,
  config: {},
  menuRef: { current: null },
});

/**
 * Contains the dropdown and maintains its open state
 */
const DropdownContainer = ({
  hoverable = false,
  className,
  disableAutoCloseTimeout = false,
  disableCloseOnClickInsideMenu = false,
  isUp = false,
  isRight = false,
  xOffset,
  yOffset,
  ...props
}: {
  className?: string;
  disableAutoCloseTimeout?: boolean;
  disableCloseOnClickInsideMenu?: boolean;
  isUp?: boolean;
  isRight?: boolean;
  xOffset?: string;
  yOffset?: string;
  hoverable?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const [active, setActive] = useState<boolean>(false);
  const timeout = useRef<any | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleMouseLeave = () => {
    if (!disableAutoCloseTimeout) {
      timeout.current = setTimeout(() => setActive(false), 250);
    }
  };

  const handleMouseEnter = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  const { children, ...rest } = props;

  return (
    <DropdownContext.Provider
      value={{
        active,
        hoverable,
        setActive,
        menuRef,
        config: { disableAutoCloseTimeout, disableCloseOnClickInsideMenu, isUp, isRight, xOffset, yOffset },
      }}>
      <div
        ref={menuRef}
        className={classnames("dropdown", { "is-active": active, "is-up": isUp, "is-right": isRight }, className)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

/**
 * Button element that triggers the menu to open
 */
const DropdownButton = (props: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  const { active, hoverable, setActive } = useContext(DropdownContext);
  return (
    <button
      className="button dropdown-trigger"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      onMouseEnter={() => hoverable && !active && setActive(true)}
      onClick={e => {
        // prevent triggering form submit when inside a form
        e.preventDefault();
        setActive(!active);
      }}
      {...props}
    />
  );
};

const DropdownContent = ({
  children,
  contentStyle,
  style,
  ...rest
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  contentStyle?: CSSProperties;
}) => {
  const {
    active,
    setActive,
    menuRef,
    config: { disableCloseOnClickInsideMenu, xOffset, yOffset },
  } = useContext(DropdownContext);

  const menuStyle: CSSProperties = useMemo(
    () => ({
      transform: `${xOffset ? `translateX(${xOffset})` : ""} ${yOffset ? `translateY(${yOffset})` : ""}`.trim(),
      zIndex: 100,
      ...style,
    }),
    [style, xOffset, yOffset]
  );

  const handleClickInsideMenu = useCallback(() => {
    setActive(false);
  }, [setActive]);

  const clickListener = useCallback(() => {
    active && setActive(false);
  }, [active, setActive]);

  useHandleClickOutside(menuRef, clickListener);

  return (
    <div className="dropdown-menu" id="dropdown-menu" role="menu" style={menuStyle} {...rest}>
      <div
        className="dropdown-content"
        onClick={disableCloseOnClickInsideMenu ? undefined : handleClickInsideMenu}
        style={{ minWidth: 400, maxHeight: 350, overflow: "auto", ...contentStyle }}>
        {active ? children : null}
      </div>
    </div>
  );
};

export const Dropdown = {
  Container: DropdownContainer,
  Button: DropdownButton,
  Content: DropdownContent,
};
