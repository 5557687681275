import { ApolloProvider } from "@apollo/client";
import HarbardProvider from "@evernowinc/harbard/provider";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import Favicon from "react-favicon";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { useEhrHarbardTheme } from "./app/hooks";
import { store } from "./app/store";
import { JSONValue } from "./app/types";
import { StorageContextProvider } from "./features/local_storage/storage_context";
import { LocalStorageKey } from "./features/local_storage/types";
import { buildHttpClient } from "./graphql/client";
import { GRAPHQL_ENDPOINT } from "./lib/constants";
import { runtimeConfig } from "./lib/constants/runtime_config";
import Routes from "./routes";

const localStorageContextDefaults: Partial<Record<LocalStorageKey, JSONValue>> = {
  featureFlagOverrides: {},
  sidebar_expanded: true,
  sort_order_follow_up: "ASC",
  sort_order_respond_to_message: "ASC",
  sort_order_welcome_patient: "ASC",
};

const App = () => {
  const harbardTheme = useEhrHarbardTheme();

  return (
    <SentryErrorBoundary>
      <ReduxProvider store={store}>
        <ApolloProvider client={buildHttpClient(GRAPHQL_ENDPOINT)}>
          <HarbardProvider theme={harbardTheme}>
            <StorageContextProvider defaultValues={localStorageContextDefaults}>
              <Favicon url={runtimeConfig.IS_PRODUCTION ? "/favicon.png" : "/favicon_dev.png"} />
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </StorageContextProvider>
          </HarbardProvider>
        </ApolloProvider>
      </ReduxProvider>
    </SentryErrorBoundary>
  );
};

App.displayName = "Evernow";

export default App;
