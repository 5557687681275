import { gql } from "graphql-request";

const USER_FIELDS_FRAGMENT = gql`
  fragment UserFields on User {
    email
    emailValidated
    id
    roles
  }
`;

const EMPLOYEE_FIELDS_FRAGMENT = gql`
  fragment EmployeeFields on Employee {
    active
    avatar {
      url
    }
    bridgeProviderId
    credentials
    dateOfBirth
    dea
    description
    displayName(includeCredentials: false, includeTitle: false)
    displayNameWithCreds: displayName(includeCredentials: true, includeTitle: false)
    displayNameWithTitle: displayName(includeCredentials: false, includeTitle: true)
    displayNameWithCredsAndTitle: displayName(includeCredentials: true, includeTitle: true)
    email
    familyName
    givenName
    id
    interests
    isDoctor
    licenses {
      id
      identifier
      state
      acceptNewVideoPts
      acceptExistingVideoPts
    }
    nickname
    npi
    phone
    phoneValidated
    pronouns
    specialty
    title
    timezone
  }
`;

export const employeesQuery = gql`
  query {
    employees {
      ...EmployeeFields
      user {
        ...UserFields
      }
    }
  }
  ${EMPLOYEE_FIELDS_FRAGMENT}
  ${USER_FIELDS_FRAGMENT}
`;

export const employeeQuery = gql`
  query ($id: ID) {
    employee(id: $id) {
      ...EmployeeFields
      user {
        ...UserFields
      }
    }
  }
  ${EMPLOYEE_FIELDS_FRAGMENT}
  ${USER_FIELDS_FRAGMENT}
`;

export const employeeWithAdminFieldsQuery = gql`
  query ($id: ID) {
    employee(id: $id) {
      ...EmployeeFields
      activePatientsByState {
        count
        state
      }
      user {
        ...UserFields
        authenticators {
          createdAt
          id
          type
        }
        id
        passwordResetCodeExpiresAt
        requiresPasswordReset
      }
    }
  }
  ${EMPLOYEE_FIELDS_FRAGMENT}
  ${USER_FIELDS_FRAGMENT}
`;

export const updateEmployeeMutation = gql`
  mutation ($data: UpdateEmployeeInput!) {
    updateEmployee(data: $data) {
      id
    }
  }
`;

export const setUserRolesMutation = gql`
  mutation ($id: ID!, $roles: [UserRole!]!, $authenticator: String!) {
    setUserRoles(id: $id, roles: $roles, authenticator: $authenticator) {
      id
      roles
    }
  }
`;
