import { useCallback, useEffect, useRef } from "react";
import { Timeout } from "react-number-format/types/types";

export const useRefreshableTimeout = (callback: () => void) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  const timeout = useRef<Timeout | null>(null);

  useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
    },
    []
  );

  return useCallback((delay: number) => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(callbackRef.current, delay);
  }, []);
};
