import Flex from "@evernowinc/harbard/flex";
import HarbardIcon from "@evernowinc/harbard/harbard_icon";
import useColors from "@evernowinc/harbard/hooks/use_colors";
import Text from "@evernowinc/harbard/text";
import { CSSProperties, Fragment, ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "../lib/dropdown";

export interface DropdownContentListItem {
  element?: ReactNode;
  iconElement?: ReactNode;
  label?: string;
  linkTo?: string;
  onClick?: VoidFunction;
}

export interface DropdownContentListProps {
  items: DropdownContentListItem[];
}

const useSxStyles = () => {
  const colors = useColors();
  return useMemo(() => {
    return {
      dropdownContentInner: {
        "border": `1px solid ${colors.divider}`,
        "borderRadius": "4px",
        "overflow": "hidden",
        "> *": {
          width: "100%",
        },
        "> a, > div": {
          "transition": "background 0.2s ease",
          "background": "transparent",
          "cursor": "pointer",
          "&:hover": {
            background: colors.grey[100],
          },
        },
      },
    };
  }, [colors]);
};

const useCssStyles = (): Record<string, CSSProperties> => {
  const colors = useColors();
  return useMemo(() => {
    return {
      dropdownContent: {
        padding: 0,
        minWidth: "232px",
      },
      dropdownContentItem: {
        padding: "20px",
        color: colors.text.primary,
      },
      dividerLine: {
        borderTop: `1px solid ${colors.divider}`,
        height: 0,
      },
    };
  }, [colors]);
};

const DropdownContentList = ({ items }: DropdownContentListProps) => {
  const cssStyles = useCssStyles();
  const sxStyles = useSxStyles();

  return (
    <Dropdown.Content contentStyle={cssStyles.dropdownContent}>
      <Flex alignItems="flex-start" flexDirection="column" justifyContent="stretch" sx={sxStyles.dropdownContentInner}>
        {items.map(({ element = null, iconElement, label, linkTo, onClick }, index) => {
          const children = (
            <>
              {iconElement && <HarbardIcon className="mr-8" iconElement={iconElement} />}
              {label ? (
                <Text component="span" variant="subtitle1">
                  {label}
                </Text>
              ) : (
                element
              )}
            </>
          );

          return (
            <Fragment key={index}>
              {linkTo ? (
                <Link className="is-flex" onClick={onClick} style={cssStyles.dropdownContentItem} to={linkTo}>
                  {children}
                </Link>
              ) : (
                <div className="is-flex" onClick={onClick} style={cssStyles.dropdownContentItem}>
                  {children}
                </div>
              )}
              {index !== items.length - 1 && <div style={cssStyles.dividerLine} />}
            </Fragment>
          );
        })}
      </Flex>
    </Dropdown.Content>
  );
};

export default DropdownContentList;
