import { ApolloError, gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { setToken } from "../features/auth/actions";

const GET_CODE = gql`
  query {
    otpQrCode(type: GOOGLE_OTP) {
      png
      secret
    }
  }
`;

const VERIFY = gql`
  mutation verifyAuthenticator($secret: String!, $data: String!) {
    verifyAuthenticator(type: GOOGLE_OTP, secret: $secret, data: $data)
  }
`;

const ENROLL = gql`
  mutation addAuthenticator($secret: String!, $data: String!) {
    addAuthenticator(type: GOOGLE_OTP, secret: $secret, data: $data) {
      token
    }
  }
`;

export const Initial2AEnrollment = () => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<string>("");
  const [valid, setValid] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const client = useApolloClient();

  const [otpQrCode, setOtpQrCode] = useState<{ png: string; secret: string }>({
    png: "",
    secret: "",
  });

  const { data: code, loading } = useQuery<{ otpQrCode: { png: string; secret: string } }>(GET_CODE);
  useEffect(() => {
    code && setOtpQrCode(code.otpQrCode);
  }, [code]);

  const [verify] = useMutation<{ verifyAuthenticator: boolean }, { secret: string; data: string }>(VERIFY);

  const [enroll] = useMutation<{ addAuthenticator: { token: string } }, { secret: string; data: string }>(ENROLL);

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const data = e.currentTarget.value;
    setData(data);
    const result = await verify({ variables: { secret: otpQrCode.secret, data } });
    setValid(result.data ? result.data.verifyAuthenticator : false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const { data: result } = await enroll({ variables: { secret: otpQrCode.secret, data } });
      if (result) {
        await client.cache.reset();
        dispatch(setToken(result.addAuthenticator.token));
      } else {
        setError("Something went wrong.");
      }
    } catch (err) {
      const { graphQLErrors, message } = err as ApolloError;
      if (graphQLErrors) {
        setError(graphQLErrors.map(e => e.message).join("\n"));
      } else {
        setError(message);
      }
    }
  };

  if (loading || !code) return null;

  return (
    <div className="section">
      <div className="container" style={{ maxWidth: "500px" }}>
        <form onSubmit={onSubmit}>
          <h2 className="is-size-3 has-text-weight-bold" style={{ margin: "2rem 0 2rem 0", lineHeight: 1 }}>
            Let&rsquo;s set up Two-Factor Authentication
          </h2>
          <p>
            Install the{" "}
            <a
              href="https://support.google.com/accounts/answer/1066447"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}>
              Google Authenticator app
            </a>{" "}
            (available for Android, iOS, and desktop) and use it to scan this barcode to configure 2FA. Then enter the
            verification code the app provides below.
          </p>
          <p style={{ marginTop: 20 }}>
            This verification code changes every 30-seconds. For extra security, you will need to launch Google
            authenticator to get a fresh verification code every time you log in.
          </p>

          <div className="has-text-centered">
            <img src={otpQrCode.png} alt="QR Code" />
          </div>

          <div className="notification is-warning">
            Do NOT scan this QR code using your phone camera app. Scan it with the Google Authenticator app!
          </div>

          <div className="field">
            <div className="label">Verification code</div>
            <div className="field">
              <input className="input" value={data} onChange={onChange} />
            </div>
            {error && (
              <div className="notification is-error" style={{ whiteSpace: "pre-line" }}>
                {error}
              </div>
            )}
            <button className="button is-primary is-rounded" disabled={!valid}>
              Enroll
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Initial2AEnrollment;
