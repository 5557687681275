import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { store } from "../app/store";
import { setToken } from "../features/auth/actions";
import { getItem } from "../features/local_storage/utils";
import { revision } from "../lib/revision";
import possibleTypes from "./possibleTypes.json";
import { getJwtFromToken, getJwtIsExpired } from "../features/auth/utils";
import { anonymize } from "../features/analytics";

const omitDeep = (obj: Object, key: string | number): Object => {
  const keys: Array<any> = Object.keys(obj);
  const newObj: any = {};

  const omitDeepArrayWalk = (arr: Array<any>, key: any): any =>
    arr.map(val => {
      if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
      else if (typeof val === "object") return omitDeep(val, key);
      return val;
    });

  keys.forEach((i: string | number) => {
    if (i !== key) {
      //@ts-ignore Unsure how to fix this.
      const val: any = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === "object" && val !== null) newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
};

const stripTypename = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  if (!forward) return null;
  return forward(operation).map(data => {
    return data;
  });
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

/**
 * SurveyQueries have multiple subqueries that return the same type. This confuses the Apollo cache because Apollo.
 *
 * The `typePolicies` key allows us to tell Apollo to chill out and prefer the new data. Likely something to think
 * about in the next major build.
 *
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
 */
const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    SurveyQueries: { merge: false },
    DoseSpotQueries: { merge: false },
    MedicationSummary: { merge: false },
    PrescriptionQueries: { merge: false },
    CheckinQueries: { merge: false },
    MedicationSummaryQueries: { merge: false },
    CmsQueries: { merge: false },

    /**
     * https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
     */
    PharmacyOrderQueries: {
      merge: false,
      fields: {
        list: {
          keyArgs: ["states", "orderBy", "orderDirection"],
          merge(existing = { items: [] }, incoming) {
            return { ...incoming, items: [...existing.items, ...incoming.items] };
          },
        },
      },
    },
    TreatmentPlanMutations: { merge: false },
    Script: {
      fields: {
        detail: {
          merge: true,
        },
      },
    },
    AiQueries: {
      merge: false,
    },
    AiAnswer: {
      keyFields: false,
    },
  },
});

export const buildHttpClient = (uri: string) => {
  const link = ApolloLink.from([
    authLink,
    stripTypename,
    onError(({ graphQLErrors, response }) => {
      if (graphQLErrors) {
        for (const e of graphQLErrors) {
          console.log(`GraphQL Error: "${e.message}." Path: "${e.path}". Code: "${e.extensions?.code || "N/A"}"`);
          if (["NOT_AUTHORIZED", "UNAUTHENTICATED"].includes(e.extensions?.code)) {
            const token = getItem<string>("token");
            const jwt = getJwtFromToken(token);
            // if the token is not expired, we should not be getting this error
            if (token && !getJwtIsExpired(jwt)) {
              Sentry.captureException(
                new Error(`ApolloClient: Unexpected graphQL ${e.extensions.code} error. Token is not expired.`),
                { extra: { error: e } }
              );
            }
            // clear the token
            store.dispatch(setToken(""));
            // anonymize analytics
            anonymize();
            // don't trigger an actual error
            response && (response.errors = undefined);
          }
        }
      }
    }),
    createHttpLink({ uri }),
  ]);

  return new ApolloClient({
    link,
    cache,
    name: "ehr",
    version: revision,
  });
};
