import type { LocalStorageKey } from "./types";

/**
 * Old versions of webkit don't allow access to localStorage in private mode. This was fixed.
 *
 * https://bugs.webkit.org/show_bug.cgi?id=157010
 */
const testLocalStorage = () => {
  if (typeof window === "undefined") return false;
  try {
    window.localStorage.setItem("_local_storage_test", "1");
    window.localStorage.removeItem("_local_storage_test");
    return true;
  } catch (e) {
    return false;
  }
};

export const hasLocalStorage = testLocalStorage();

const isNullish = (val: any) => val === null || val === undefined;

export type StorageChange = "SET" | "REMOVE";
export type StorageListener = (key: LocalStorageKey, change?: StorageChange) => void;

let listeners: StorageListener[] = [];

const publishStorageChange = (key: LocalStorageKey, change: StorageChange) => {
  listeners.forEach(listener => listener(key, change));
};

export const subscribeToStorageChange = (listener: StorageListener) => {
  listeners.push(listener);
};

export const unsubscribeStorageChange = (listener: StorageListener) => {
  listeners = listeners.filter(l => l !== listener);
};

/**
 * Safely attempts to parse any value. This is important because we are running
 * JSON.stringify on every value we put into localStorage. This means we could end up
 * with strings that look like this for exampe: '"foo"'
 *
 * This function can take the following values and return them all correctly:
 * '"foo"' -> "foo"
 * "foo" -> "foo"
 * { bar: "foo" } -> { bar: "foo" }
 * '{ "bar": "foo" }' -> { bar: "foo" }
 * ["foo"] -> ["foo"]
 * '["foo"]' -> ["foo"]
 * etc...
 *
 * Also works as expected with numbers and other values like null or undefined.
 * "4" -> 4
 * 4 -> 4
 * etc...
 */
const safeParse = <T>(value: any): T => {
  try {
    const json = `{ "value": ${value} }`;
    return JSON.parse(json).value as T;
  } catch (_) {
    return value as T;
  }
};

export const getItem = <T>(key: LocalStorageKey) => {
  if (!hasLocalStorage) return null;
  try {
    const val = window.localStorage.getItem(key);
    if (isNullish(val)) return null;
    const parsedVal: T = safeParse(val);
    return parsedVal;
  } catch (_) {
    return null;
  }
};

export const setItem = <T>(key: LocalStorageKey, val: T) => {
  if (!hasLocalStorage) return;
  try {
    window.localStorage.setItem(key, JSON.stringify(val));
    publishStorageChange(key, "SET");
  } catch (_) {
    return;
  }
};

export const removeItem = (key: LocalStorageKey) => {
  if (!hasLocalStorage) return;
  try {
    window.localStorage.removeItem(key);
    publishStorageChange(key, "REMOVE");
  } catch (_) {
    return;
  }
};
