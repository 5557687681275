import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmployeeTasksStoreState } from "./types";

export const initialState: EmployeeTasksStoreState = {
  // A string indicates a selected employee, null indicates all employees
  // selected, and undefined indicates the UI is still initializing.
  selectedEmployeeId: undefined,
};

export const employeeTasksSlice = createSlice({
  name: "employeeTasks",
  initialState,
  reducers: {
    setSelectedEmployeeId: (state, action: PayloadAction<string | null>) => {
      state.selectedEmployeeId = action.payload === "ALL" ? null : action.payload;
    },
  },
});

export default employeeTasksSlice.reducer;
