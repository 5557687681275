import { useRef } from "react";

// Allows you to wrap any value in a ref so that it won't trigger
// re-renders when being used inside hook dependency arrays.
const useRefWrappedValue = <T>(value: T) => {
  const valueRef = useRef(value);
  valueRef.current = value;
  return valueRef;
};

export default useRefWrappedValue;
