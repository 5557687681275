import { useEffect } from "react";
import useRefWrappedValue from "./use_ref_wrapped_value";

export const useInterval = (callback: VoidFunction, delay: number) => {
  const callbackRef = useRefWrappedValue(callback);

  useEffect(() => {
    const tick = () => callbackRef.current?.();
    const interval = setInterval(tick, delay);
    return () => clearInterval(interval);
  }, [callbackRef, delay]);
};
