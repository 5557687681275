import Box from "@evernowinc/harbard/box";
import HarbardIcon from "@evernowinc/harbard/harbard_icon";
import useColors from "@evernowinc/harbard/hooks/use_colors";
import Text from "@evernowinc/harbard/text";
import { lighten } from "@evernowinc/harbard/utils/color_tools";
import classnames from "classnames";
import { memo, ReactNode, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

export interface SidebarItemProps {
  item: {
    title: string;
    link: string;
    iconElement: ReactNode;
    notificationCount?: number;
  };
  sidebarIsOpen: boolean;
}

const useStyles = () => {
  const colors = useColors();

  return useMemo(() => {
    return {
      listItem: {
        a: {
          "transition": "background .2s ease",
          "&, &:hover": {
            color: colors.text.primary,
          },
          "&:hover": {
            background: lighten(colors.primary.dark, 0.4),
          },
          "&.active": {
            background: colors.primary.dark,
          },
        },
      },
      listItemNotification: {
        background: colors.error.dark,
        color: colors.error.contrastText,
        borderRadius: "100%",
        height: "16px",
        width: "16px",
        marginTop: "-5px",
      },
    };
  }, [colors]);
};

const SidebarItem = memo(({ item, sidebarIsOpen }: SidebarItemProps) => {
  const { title, link, iconElement, notificationCount } = item;
  const { pathname } = useLocation();
  const styles = useStyles();

  return (
    <Box key={title} component="li" sx={styles.listItem}>
      <Link className={classnames("is-flex p-16 pl-30", { active: pathname.startsWith(link) })} to={link} title={title}>
        <HarbardIcon iconElement={iconElement} />
        {sidebarIsOpen && (
          <Text component="span" className="ml-8" variant="subtitle1">
            {title}
          </Text>
        )}
        {!!notificationCount && (
          <Text
            component="span"
            className="is-flex is-align-items-center is-justify-content-center ml-2"
            variant="overline"
            sx={styles.listItemNotification}>
            {notificationCount}
          </Text>
        )}
      </Link>
    </Box>
  );
});

SidebarItem.displayName = "SidebarItem";
export default SidebarItem;
